<template>
	<div class="orderinfo">
		<div class="tofilm-top">
			<div class="tofilm-title">
				<div class="title">配音订单号：{{orderInfoData.order_id}}</div>
				<div class="anniu"><el-button type="primary" @click="tofilmList">返回订单列表</el-button></div>
			</div>
			<div class="tofilm-prompt">
				<!-- <div class="text">配音工作时间：周一到周五9:30 - 12:30  14:00 - 18:30</div> -->
			</div>
		</div>
		<div class="orderinfo-bottom">
			<div class="dan">
				<div class="title">下单时间：</div>
				<div class="text">{{orderInfoData.created_at}}</div>
			</div>
			<div class="dan">
				<div class="title">订单状态：</div>
				<div class="text">{{orderInfoData.status == 1 ? '未付款' : orderInfoData.status == 2 ? '配音中' : orderInfoData.status == 3 ? '配音中' : orderInfoData.status == 4 ? '已完成' : orderInfoData.status == 5 ? '已退款' : orderInfoData.status == 6 ? '订单失效' : ''}}</div>
				<div class="text"><el-tag v-if="orderInfoData.status == 1" type="danger" effect="dark" @click="onpayorder(orderInfoData)">付款</el-tag></div>
				<!-- <div class="text" v-if="orderInfoData.record_status"><el-button type="success" @click="onDownload">下载文件</el-button></div> -->
			</div>
			<div class="dan">
				<div class="title">配音师：</div>
				<div class="text">{{orderInfoData.mixer}}</div>
			</div>
			<div class="dan">
				<div class="title">配音风格：</div>
				<div class="text">{{orderInfoData.style_name}}</div>
			</div>
			<div class="dan">
				<div class="title">配音时长：</div>
				<div class="text">{{orderInfoData.duration}}秒</div>
			</div>
			<div class="dan">
				<div class="title">配音文字：</div>
				<div class="text">{{orderInfoData.content}}</div>
			</div>
			<div class="dan">
				<div class="title">备注：</div>
				<div class="text">{{orderInfoData.remark}}</div>
			</div>
			<!-- <div class="dan" v-if="orderInfoData.record_status">
				<div class="title">配音是否修改：</div>
				<div class="text"></div>
			</div> -->
			<div class="dan">
				<div class="title">支付方式：</div>
				<div class="text">{{orderInfoData.payment_method == 1 ? '在线支付' : '积分支付'}}</div>
			</div>
			
			<div class="dan">
				<div class="title">
					<el-tooltip class="item" effect="dark" content="文件下载超过48小时不可修改！" placement="top">
						<i class="el-icon-question"></i>
					</el-tooltip>
					修改配音失效时间：
				</div>
				<div class="text">{{orderInfoData.failure_time}}</div>
			</div>
		</div>
		<div class="textwenj">
			<div class="tofilm-top ">
				<div class="tofilm-title">
					<div class="title">配音文件下载</div>
				</div>
			</div>
			<el-table :data="RecordList" border style="width: 100%">
				<el-table-column prop="title" label="配音文件标题">
					<template slot-scope="scope">
						{{scope.row.title ? scope.row.title : '--'}}
					</template>
				</el-table-column>
				<el-table-column prop="remark" label="修改建议">
					<template slot-scope="scope">
						{{scope.row.remark ? scope.row.remark : '--'}}
					</template>
				</el-table-column>
				<el-table-column prop="created_at" label="提交日期"> </el-table-column>
				<el-table-column prop="created_at" label="配音状态">
					<template slot-scope="scope">
						<el-button type="warning" v-if="scope.row.status == 2" plain>配音中</el-button>
						<el-button type="success" v-if="scope.row.status == 1" plain>已完成</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="操作" width="280">
					<template slot-scope="scope">
						<div class="flex">
							<el-button type="success" v-if="scope.row.is_sati == 1 && scope.row.status == 1" @click="onDownloadFile(scope.row)">下载文件</el-button>
							<el-button type="success" v-if="scope.row.status == 1" @click="onMobTheShow(scope.row)">配音试听</el-button>
							<div v-else>--</div>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<Payorder ref="Payorder"></Payorder>
		<el-dialog :title="'声音试听（' + DialogData.send_time + '）'" :visible.sync="ModThedialog" :before-close="onBefore" width="700px" custom-class="pyinfoclass">
			<div class="xiugtij">
				<div class="opention">
					<div class="text">声音试听：</div>
					<div class="radio"><el-button type="primary" :icon="!aduioTion ? 'el-icon-video-play' : 'el-icon-video-pause'" round @click="onAudioPlay()">点击{{!aduioTion ? '播放' : '暂停'}}</el-button></div>
					<audio ref="audio" :src="audioUrl" style="display:none"></audio>
				</div>
				<div class="opention" v-if="DialogData.is_sati == 3">
					<div class="text">是否满意（满意后可下载配音文件）：</div>
					<div class="radio">
						<el-radio v-model="openradio" label="1">满意（满意后无法修改配音）</el-radio>
						<el-radio v-model="openradio" label="2">不满意</el-radio>
					</div>
				</div>
				<el-input v-if="openradio == 2" type="textarea" :rows="4" placeholder="请输入修改意见" v-model="Opinion"></el-input>
				<el-tooltip class="item" effect="dark" content="满意后可下载配音文件" placement="top">
					<el-button type="primary"  v-if="DialogData.is_sati == 3" @click="onTijiao">提交反馈</el-button>
				</el-tooltip>
			</div>
		</el-dialog> 
	</div>
</template>

<script>
import Payorder from '../tofilm/payorder.vue'
export default {
	data () {
		return {
			orderInfoData:{},//详细信息
			ModThedialog:false,
			Opinion:'',//配音修改意见
			RecordList:[],
			openradio:'1',
			audioUrl:'http://erlangcha.oss-cn-beijing.aliyuncs.com/peiyin/order/1d9e22210d5181273872c0eb3f26551120210921100331.m4a',//声音连接
			DialogData:{},//弹窗里的内容参数
			aduioTion:false,

		}
	},
	components:{
		Payorder,
	},
	mounted(){
		this.onorderinfo()
	},

	methods: {
		//弹窗关闭触发动作
		onBefore(){
			this.$refs.audio.pause();
			this.ModThedialog = false,
			this.openradio = '1',
			this.audioUrl = '',//声音连接
			this.DialogData = {},//弹窗里的内容参数
			this.aduioTion = false
		},
		//播放音频
		onAudioPlay(){
			if(!this.aduioTion){
				this.$refs.audio.play();//播放
			}else{
				this.$refs.audio.pause();//暂停
			}
			this.aduioTion = !this.aduioTion
		},
		// 调取详细信息接口
		onorderinfo(){
			var param = {
				order_id : this.$route.query.order_num
			}
			this.$service.get(this.$api.pcGetDubOrderInfo, param, (res)=> {
				if(res.code == '200'){
					this.orderInfoData = res.data
					this.onDownload()
				}
			})
		},
		//跳转列表
		tofilmList(){
			this.$router.push('/voice')
		},
		//调取支付
		onpayorder(row){
			if(this._isMobile){
				this.onClickJsapi()
			}else{
				this.$refs.Payorder.onOrdertext('配音')
				this.$refs.Payorder.enterRouter('/voice')
				this.$refs.Payorder.onImgmorny(row.qr_code,row.cost,row.order_id,row.time_expire)
			}
			
		},
		// 移动端支付点击
		onClickJsapi(){
			if(this._isMobile && this._isMicrom){
				alert('请用微信打开，否则不能在线支付')
			}
			else{
				this.onJsapiZhifu()
			}
		},
		// jsapi移动端支付接口参数收取
		onJsapiZhifu(){
			var param = {
				order_id:this.$route.query.order_num,
			}
			this.$service.post(this.$api.pcDubOrderPayByJsapi,param, (res)=> {
				if(res.code == '200'){
					this.$refs.Payorder.onBridgeReady(res.data.data)
					this.$refs.Payorder.onOrdertext('配音')
					this.$refs.Payorder.enterRouter('/voice')
				}
			})
		},
		//配音修改弹窗
		onMobTheShow(data){
			this.audioUrl = data.file_url,//声音连接
			this.DialogData = data
			this.ModThedialog = true
		},
		//提交修改配音
		onTijiao(){
			if(this.Opinion == '' && this.openradio == 2){this.$message({message: '请填写修改意见', type: 'warning'}); return}
			var param = {
				order_id:this.orderInfoData.id,
				record_id:this.DialogData.id,
				remark:this.Opinion,
				is_sati:this.openradio
			}
			this.$service.post(this.$api.pcGetRecordFeedblck,param, (res)=> {
				if(res.code == '200'){
					this.$message({message:this.openradio == 1 ? '反馈成功，配音文件意见可以下载' : '提交完成请等待修改配音', type: 'success'})
					this.ModThedialog = false
					this.Opinion = ''
					this.onorderinfo()
				}
			})
		},
		//配音文件列表
		onDownload(){
			var param = {
				order_id:this.orderInfoData.id,
			}
			this.$service.post(this.$api.pcGetRecordList,param, (res)=> {
				if(res.code == '200'){
					this.RecordList = res.data
				}
			})
		},
		//配音下载列表
		onDownloadFile(row){
			console.log(this.$api.dubOrderDownloadFile + '?id=' + row.id + '&token=' + JSON.parse(localStorage.getItem('TZ-USER')).token)
			window.open(this.$api.dubOrderDownloadFile + '?id=' + row.id + '&token=' + JSON.parse(localStorage.getItem('TZ-USER')).token)
		}
	}
}
</script>

<style lang="scss" scoped>
.orderinfo{
	.tofilm-top{
		border-bottom: 1px solid #ededed;
		padding-bottom: 5px;
		
		.tofilm-title{
			display: flex;
			font-weight: 500;
			padding-bottom: 15px;
			.title{
				font-size: 22px;
			}
			.anniu{
				margin-left: 10px;
			}
		}
		.tofilm-prompt{
			display: flex;
			justify-content: space-between;
			.horn{
				padding: 5px 10px;
				background: #fefae9;
				color: #ff9a56;
			}
		}
	}
	
	.orderinfo-bottom{
		margin-top: 20px;
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;
		.dan{
			display: flex;
			// height: 40px;
			align-items: center;
			padding-right: 20px;
    		box-sizing: border-box;
			width: 33.33%;
			border-bottom: 1px solid #f9f9f9;
			padding-top: 15px ;
			padding-bottom: 15px ;
			.title{
				font-size: 16px;
				color: #333;
				font-weight: 500;
				min-width: 100px;
			}
			.text{
				color: #666;
				font-size: 16px;
				&:nth-child(3){
					margin-left: 10px;
				}
			}
		}
	}
	.textwenj{
		margin-top: 30px;
	}
}
@media screen and (max-width: 750px) {
	.orderinfo{
		.tofilm-top{
			.tofilm-prompt{
				display: block;
				.text{
					margin-bottom: 10px;
				}
				
			}
		}
		.orderinfo-bottom{
			display: block;
			.dan{
				width: 100%;
			}
		}
	}
}
</style>
<style lang="scss">
.orderinfo{
	.textwenj{
		.el-table{
			margin-top: 30px;
			.cell{
				text-align: center;
			}
			.flex{
				display: flex;
				justify-content: center;
			}
		}
	}
}
.pyinfoclass{
	.xiugtij{
		text-align: center;
		.opention{
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			.el-button{
				margin-top: 0;
			}
		}
		.el-button{
			margin: 0 auto;
			margin-top: 20px;
		}
	}
}
.Recordclass{
	.el-table{
		.cell{
			text-align: center;
		}
	}
}

</style>
